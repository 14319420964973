import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { auth,database as db } from './pages/firebase.js'; // Make sure db is imported
import { ref, get } from 'firebase/database'; // Import Realtime Database functions

function Header() {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  const login = () => {
    navigate("login");
  };

  const registre = () => {
    navigate("registe");
  };
  const registreo = () => {
    navigate("registeo");
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const userRef = ref(db, 'users/' + user.uid);
        const userSnap = await get(userRef);
        if (userSnap.exists()) {
          setRole(userSnap.val().role);
        }
      } else {
        setUser(null);
        setRole('');
      }
    });

    return () => unsubscribe();
  }, [user,navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  const loginButton = (
    <button
      id="loginBtn"
      className="btn btn-secondary"
      onClick={login}
    >
      دخول<i class="fas fa-sign-in-alt"></i>
    </button>
  );

  const registerButton = (
    <button
      id="registerBtn"
      className="btn btn-secondary"
      onClick={registre}
    >
      التسجيل<i class="fas fa-user-plus"></i>
    </button>
  );
  const registerButtono = (
    <button
      id="registerBtn"
      className="btn btn-secondary"
      onClick={registreo}
    >
     سجل كمالك عقار<i class="fas fa-building"></i>
    </button>
  );

  const logoutButton = (
    <button
      id="logoutBtn"
      className="btn btn-secondary"
      onClick={handleLogout}
    >
      خروج<i class="fas fa-sign-in-alt"></i>
    </button>
  );

  const controlPanelButton = (
    <button
      id="controlPanelBtn"
      className="btn btn-secondary"
      onClick={() => navigate("/dashbord")}
    >
      لوحة التحكم<i class="fas fa-users-cog"></i>
    </button>
  );
  const usercontrolPanelButton = (
    <button
      id="usercontrolPanelBtn"
      className="btn btn-secondary"
      onClick={() => navigate('/userdashbord/'+user.uid)}
    >
      صفحة المستخدم<i class="fas fa-users-cog"></i>
    </button>
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-light">
      <a className="navbar-brand flex" href="#">
        <img
          src="icon.jpg"
          alt="Icon"
          style={{ width: 30, height: 30 }}
          className="d-inline-block align-top mr-2"
        />
       <strong className='text-dark'> Résidence Ramdane</strong>
      </a>
      <button className="navbar-toggler bg-dark" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ">
          <li className="nav-item">
            <Link className="btn btn-secondary aling-center" to="/"><i class="fa-solid fa-house"></i>
              الصفحة الرئيسة <i class="fas fa-home"></i></Link>
          </li>
          <li className="nav-item">
            <Link className="btn btn-secondary alig-center" to="/about">عن الموقع<i class="far fa-flag"></i></Link>
          </li>
        </ul>
        <div className="btn-group d-flex justify-content-end col-8" role="group" aria-label="Authentication buttons">
          {!user && (
            <>
            {registerButtono}
              {loginButton}
              {registerButton}
            </>
          )}
          {user && (
            <>
            {role === 'renter' && usercontrolPanelButton}
            {role === 'renter' && registerButtono}
              {role === 'property_owner' && controlPanelButton}
              {logoutButton}
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Header;
