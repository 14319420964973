import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, push, set } from 'firebase/database';
import { auth, database } from './firebase';
import { getAuth } from 'firebase/auth';
import { useParams, useNavigate } from 'react-router-dom';
import "./Chat.css"
function Chat() {
    const [userId, setUserId] = useState(null)
const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      setUserId(user.uid); // Set user ID if user is authenticated
    }
  }, []); 

  useEffect(() => {
    const messagesRef = ref(database, `chats/${chatId}/messages`);
    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      const messagesArray = data ? Object.values(data) : [];
      setMessages(messagesArray);
    });

    return () => unsubscribe();
  }, [chatId]);

  const sendMessage = async () => {
    if (messageText.trim() === '') return;

    const messagesRef = ref(database, `chats/${chatId}/messages`);
    const newMessageRef = push(messagesRef);
    await set(newMessageRef, {
      text: messageText,
      senderId: userId,
      timestamp: Date.now(),
    });

    setMessageText('');
  };

  return (
    <div className="chat-container">
      <div className="messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.senderId === userId ? 'sent' : 'received'}`}>
            <p>{message.text}</p>
          </div>
        ))}
      </div>
      <div className="message-input">
        <input
          type="text"
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
}

export default Chat;