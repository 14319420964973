import React from 'react';

function AboutPage() {
  return (
    <div className='container p-5'>
      <h4 class="text-center width: 8rem font-weight-bold align-middle border">عن الموقع </h4>
      <div class="row border">
       <div class="col-12"><i class="fas fa-home"></i>الموقع عبارة عن ادات تمكن من حجز شقق  للعطلة الصيفية قريبة من البحر الحجز لا يكون مؤكد الى بعد تأكيد الحجز الموقع ليس للتعاقد ويعفى من المسؤولية العقدية
      </div>
      </div>
    </div>
  );
}

export default AboutPage;