import React, { useState } from 'react';
import { ref, update } from 'firebase/database';
import { database } from './firebase';

function Book({ booking, user, property, handleDelete, onConfirm, deleteo }) {
  const [showModal, setShowModal] = useState(false);

  const handleConfirm = async () => {
    try {
      const bookingRef = ref(database, `bookings/${booking.id}`);
      await update(bookingRef, { etat: 'confirm' });
      onConfirm(booking.id, 'confirm');
    } catch (error) {
      console.error('Error confirming booking:', error);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <tr >
        <td className="d-none d-sm-table-cell">{booking.id}</td>
        <td>{property ? property.title : 'Loading...'}</td>
        {deleteo && <td >{user ? user.fullName : 'Loading...'}</td>}
        {deleteo && <td className="d-none d-sm-table-cell">{user ? user.phoneNumber : 'Loading...'}</td>}
        <td className="d-none d-sm-table-cell">{booking.startDate}</td>
        <td className="d-none d-sm-table-cell">{booking.endDate}</td>
        <td>{booking.etat}</td>
        {deleteo && (
          <td className="d-none d-sm-table-cell">
            <button className="btn btn-success" onClick={handleConfirm}>
              Confirm Booking
            </button>
          </td>
        )}
        <td className="d-none d-sm-table-cell">
          <button className="btn btn-danger" onClick={() => handleDelete(booking.id)}>
            حذف الحجز
          </button>
        </td>
      </tr>

      <tr >
        <td>
          <button className="btn btn-info d-sm-none" onClick={handleShowModal}>
            اظهر المعلومات
          </button>
        </td>
      </tr>

      <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Reservation Info</h5>
              <button type="button" className="close" onClick={handleCloseModal} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Booking ID: {booking.id}</p>
              <p>Property: {property ? property.title : 'Loading...'}</p>
              {deleteo && <p>User: {user ? user.fullName : 'Loading...'}</p>}
              {deleteo && <p>Phone: {user ? user.phoneNumber : 'Loading...'}</p>}
              <p>Start Date: {booking.startDate}</p>
              <p>End Date: {booking.endDate}</p>
              <p>Status: {booking.etat}</p>
            </div>
            <div className="modal-footer">
              {deleteo && (
                <button className="btn btn-success" onClick={handleConfirm}>
                  Confirm Booking
                </button>
              )}
              <button type="button" className="btn btn-danger" onClick={() => handleDelete(booking.id)}>
                حذف الحجز
              </button>
              <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Book;
