import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, update } from 'firebase/database';
import 'bootstrap/dist/css/bootstrap.min.css';

function Userdata({ userId }) {
  const [userData, setUserData] = useState({
    fullName: '',
    address: '',
    email: '',
    phoneNumber: '',
    role: '',
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const database = getDatabase();
      const userRef = ref(database, `users/${userId}`);
      const userSnapshot = await get(userRef);

      if (userSnapshot.exists()) {
        setUserData(userSnapshot.val());
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      const { role, ...dataToUpdate } = userData; // Exclude role from the data to update
      const database = getDatabase();
      const userRef = ref(database, `users/${userId}`);
      await update(userRef, dataToUpdate);
      alert('User data updated successfully!');
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="card shadow-sm p-4">
        <h2 className="mb-4 text-center">بيانات المستخدم</h2>
        {!isEditing ? (
          <div>
            <p><strong>الاسم الكامل:</strong> {userData.fullName}</p>
            <p><strong>العنوان:</strong> {userData.address}</p>
            <p><strong>البريد الإلكتروني:</strong> {userData.email}</p>
            <p><strong>رقم الهاتف:</strong> {userData.phoneNumber}</p>
            <div className="text-center mt-4">
              <button className="btn btn-primary" onClick={() => setIsEditing(true)}>
                تعديل البيانات
              </button>
            </div>
          </div>
        ) : (
          <form>
            <div className="mb-3">
              <label className="form-label">الاسم الكامل</label>
              <input
                type="text"
                className="form-control"
                name="fullName"
                value={userData.fullName}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">العنوان</label>
              <input
                type="text"
                className="form-control"
                name="address"
                value={userData.address}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">البريد الإلكتروني</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={userData.email}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">رقم الهاتف</label>
              <input
                type="text"
                className="form-control"
                name="phoneNumber"
                value={userData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="text-center">
              <button type="button" className="btn btn-primary" onClick={handleUpdate}>
                تحديث البيانات
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default Userdata;

