import React, { useState } from 'react';
import { storage, database, auth } from './firebase.js';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as databaseRef, push } from "firebase/database";
import imageCompression from 'browser-image-compression';

function ProductForm({ onAddProduct, closeForm }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('electronics');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleImageChange = (e) => {
    setImages(e.target.files);
  };

  const handleSubmit = async () => {
    if (!title || !price || images.length === 0) {
      setErrorMessage('Please fill out all fields and upload at least one image.');
      return;
    }

    const product = {
      title,
      price,
      category,
      description,
      ownerId: auth.currentUser.uid,
      images: []
    };

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };

      const compressImagePromises = Array.from(images).map(async (imageFile) => {
        return await imageCompression(imageFile, options);
      });

      const compressedImages = await Promise.all(compressImagePromises);

      const uploadPromises = compressedImages.map(imageFile => {
        const imageRef = storageRef(storage, 'products/' + imageFile.name);
        return uploadBytes(imageRef, imageFile).then(snapshot => getDownloadURL(snapshot.ref));
      });

      const imageUrls = await Promise.all(uploadPromises);
      product.images = imageUrls;
      product.image = imageUrls[0];

      const newProductRef = await push(databaseRef(database, 'products'), product);
      onAddProduct({ id: newProductRef.key, ...product });
      alert("Product added successfully!");

    } catch (error) {
      setErrorMessage('Failed to add product. Please try again later.');
    }
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="modal" tabIndex="-1" role="dialog" id="productModal">
      <div className="modal-content">
        <div className="modal-header d-flex justify-content-end">
          <h5 className="modal-title">Add New Product</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body d-flex">
          <form className="flex-grow-1">
            {currentStep === 1 && (
              <div>
                <div className="form-group row">
                  <div className="col-3">
                    <label htmlFor="product_title">Product Title:</label>
                  </div>
                  <div className="col-9">
                    <input
                      style={{ width: '80%' }}
                      id="product_title"
                      type="text"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-3">
                    <label>Price:</label>
                  </div>
                  <div className="col-9">
                    <input
                      style={{ width: '80%' }}
                      type="number"
                      value={price}
                      onChange={e => setPrice(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <button type="button" onClick={nextStep} className="btn btn-primary">Next</button>
              </div>
            )}
            {currentStep === 2 && (
              <div>
                <div className="form-group row">
                  <div className="col-4">
                    <label>Product Category:</label>
                  </div>
                  <div className="col-8">
                    <select value={category} onChange={e => setCategory(e.target.value)}>
                      <option value="electronics">Electronics</option>
                      <option value="fashion">Fashion</option>
                      <option value="home">Home</option>
                      <option value="beauty">Beauty</option>
                      <option value="sports">Sports</option>
                      <option value="automotive">Automotive</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <div className="d-flex align-items-end">
                    <div className="p-2">
                      <label>Product Description:</label>
                    </div>
                  </div>
                  <textarea
                    style={{ width: '100%' }}
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
                <button type="button" onClick={prevStep} className="btn btn-secondary">Back</button>
                <button type="button" onClick={nextStep} className="btn btn-primary">Next</button>
              </div>
            )}
            {currentStep === 3 && (
              <div>
                <div className="form-group">
                  <label>Product Images:</label>
                  <input
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                    multiple
                    required
                  />
                </div>
                <button type="button" onClick={prevStep} className="btn btn-secondary">Back</button>
                <button type="button" onClick={handleSubmit} className="btn btn-primary">Submit</button>
              </div>
            )}
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProductForm;
