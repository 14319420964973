import React from 'react';

function Carose({images}) {
  return (
    <div id="propertyDetailsCarousel" className="carousel slide mt-5" data-ride="carousel">
        <div className="carousel-inner" id="propertyImages">
          {images.map((image, index) => (
            <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
              <img src={image} className="d-block w-100" style={{ width: 400, height: 400 }} alt="Property" />
            </div>
          ))}
        </div>
        <a className="carousel-control-prev" href="#propertyDetailsCarousel" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#propertyDetailsCarousel" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
  );
}

export default Carose;