import React, { useEffect, useState } from 'react';
import { ref, get, push, set } from 'firebase/database';
import { useParams, useNavigate } from 'react-router-dom';
import Carose from './Carose';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, database } from './firebase';
import UserInfoModal from './UserInfoModal';
import Rating from './Rating';

function ProductDetail() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(database, `products/${productId}`);
      const snapshot = await get(dbRef);

      if (snapshot.exists()) {
        const productData = snapshot.val();
        productData.id = productId;
        setProduct(productData);
      }
    };

    fetchData();
  }, [productId]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const buyProduct = async () => {
   // if (!user) {
      setShowModal(true);
      return;
   // }

    try {
      const purchasesRef = ref(database, 'purchases');
      const newPurchaseRef = push(purchasesRef);
      await set(newPurchaseRef, {
        productId,
        userId: user.uid,
      });
      alert('Product purchased successfully!');
    } catch (error) {
      console.error('Purchase failed:', error.message);
    }
  };
  const handleChatWithOwner = async () => {
    if (!user) {
      alert("لطرح سؤال حول المنتج يجب تسجيل الدخول")
      navigate("/login")
      return;
    } 
    const userId = auth.currentUser.uid;
    const ownerId = product.ownerId
    

    const chatsRef = ref(database, 'chats');
    const chatSnapshot = await get(chatsRef);
    let chatId;

    // Check if a chat already exists
    chatSnapshot.forEach((childSnapshot) => {
      const chatData = childSnapshot.val();
      if (
        chatData.participants[userId] &&
        chatData.participants[ownerId]
      ) {
        chatId = childSnapshot.key;
      }
    });

    if (!chatId) {
      // Create a new chat room
      const newChatRef = push(chatsRef);
      chatId = newChatRef.key;

      await set(newChatRef, {
        participants: {
          userId: userId,
          ownerId:ownerId ,
        },
        messages: {},
      });
    }

    navigate(`/chat/${chatId}`);
  };

  const handleModalSave = async (userInfo) => {
    try {
      const purchasesRef = ref(database, 'purchasesfull');
      const newPurchaseRef = push(purchasesRef);
      await set(newPurchaseRef, {
        productId,
        userId: 'guest', // Mark as a guest purchase
        ...userInfo,
      });
      alert('Product purchased successfully!');
      setShowModal(false);
    } catch (error) {
      console.error('Purchase failed:', error.message);
    }
  };

  return (
    <div className="container mt-5">
      {product ? <Carose images={product.images} /> : ""}
      <div className="mt-3">
        <div className='row'>
          <div className="col-sm-6 col-12">
            <div className="row p-3">
              <div className="col-12 text-center border">
                <h2 id="productTitle bg-light">{product?.title}</h2>
              </div>
            </div>
            <div className="row border p-2">
              <div className="col-12 m-1">
                <p id="productDescription">{product?.description}</p>
              </div>
              <button onClick={buyProduct} className="btn btn-primary bg-light text-dark">
                شراء المنتج
              </button>
            </div>
          </div>
          <div className='col-sm-6 col-'>
            <div className="container border p-5">
              <div className="row">
                <div className="col-12 text-center">Price</div>
              </div>
              <div className="row">
                <div id="priceDisplay" className="col-12 text-center align-middle">Price: ${product?.price}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Rating property={product} userId={user?user.uid:user} />
      <button onClick={handleChatWithOwner} className="btn btn-primary bg-light text-dark">
      اطرح سؤال عن المنتج
      </button>
      {showModal && <UserInfoModal onClose={() => setShowModal(false)} onSave={handleModalSave}  pricepruduct={product?.price}/>}
    </div>
  );
}

export default ProductDetail;
