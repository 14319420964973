
import PropertyCard from './PropertyCard'; 
import { database } from './firebase.js';
import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, remove } from 'firebase/database';

const Propertylist = ({ deleteo, user }) => {
  
  const [properties, setProperties] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      const propertiesRef = ref(database, 'properties');
      const snapshot = await get(propertiesRef);
      const propertyList = [];
      snapshot.forEach(childSnapshot => {
        const propertyId = childSnapshot.key;
        const propertyData = childSnapshot.val();
       
        if(user)
        {
        propertyList.push({
          id: propertyId,
          deletable: deleteo,
          userID: user.uid,
          ...propertyData
        });}
        else{
          propertyList.push({
            id: propertyId,
            deletable: deleteo,
            userID: "",
            ...propertyData
          });
        }
      });
      setProperties(propertyList);
    };

    fetchData();
  }, [deleteo, user]);

  const handleDelete = async (id) => {
    try {
      const propertyRef = ref(database, `properties/${id}`);
      await remove(propertyRef);
      setProperties(prevProperties => prevProperties.filter(property => property.id !== id));
    } catch (error) {
      console.error('Error deleting property:', error);
    }
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          <h2 className="mb-4 border" style={{ textAlign: 'center' }}>
            قائمة العقارات<i className="fas fa-house-return"></i>
          </h2>
        </div>
      </div>
      {!deleteo?<div className="row">
        {properties.map(property => (
          <PropertyCard key={property.id} onDelete={handleDelete} property={property} />
        ))}
      </div>:<div className="row">
        {properties.filter(property => property.ownerId === property.userID).map(property => (
          <PropertyCard key={property.id} onDelete={handleDelete} property={property} />
        ))}
      </div>}
     
    </div>
  );
};

export default Propertylist;