import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { getDatabase, ref, get, remove } from 'firebase/database';
import { app, auth, database } from './firebase';
import  "./PropertyCard.css"

const PropertyCard = ({ property , onDelete}) => {
  const navigate = useNavigate();

  const handlePropertyClick = () => {
    if(!property.deletable)
    navigate(`/property-detail/${property.id}`);
  };

if(property.confirmed)
{
  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"> 
      <div 
        className="card property card property-card h-100" 
        style={{ cursor: 'pointer' }} 
        
      >
        <img 
          src={property.image} 
          className="card-img-top card-img-top" 
          alt={property.title} 
        />
        <div className="card-body">
          <h5 className="card-title" style={{textAlign:'right'}}>{property.title}</h5>
          <p className="card-text"  style={{textAlign:'right'}}>
            <strong>Price start from:</strong> {property.price} DZD
          </p>
          <p className="card-text"  style={{textAlign:'right'}}>
            <strong>place:</strong> {property.commun}
          </p>
          {!property.deletable?  <a  class="btn btn-primary"onClick={handlePropertyClick}>التفاصيل </a>:""}
       {property.deletable? <a   class="btn btn-primary"onClick={() => onDelete(property.id)}>حدف</a>:""}
          {/* Add more details here */}
        </div>
      </div>
    </div>
  );}else return("")
};

export default PropertyCard;