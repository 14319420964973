import React from 'react';
import {Navigate, BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import IndexPage from './pages/IndexPage';
import AboutPage from './pages/AboutPage';
import LoginPage from './pages/LoginPage';
import RegistPage from './pages/RegistPage';
import RegistPageo from './pages/RegistPageo';
import DashboardPage from './pages/DashboardPage';
import PropertyDetailPage from './pages/PropertyDetailPage';
import Header from './Header';
import Footer from './Footer';
import Chat from "./pages/Chat"
import ReservationPage from './pages/ReservationPage'
import ProductDetail from './pages/ProductDetail';

function App() {
  return (
    <Router>
      <div className="app-container">
 <Header />
      

        <main className="app-main">
          <Routes>
            <Route path="/" element={<IndexPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path='/userdashbord/:userId' element={<ReservationPage/>}/>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/registe" element={<RegistPage />} />
            <Route path="/registeo" element={<RegistPageo />} />
            <Route path="/property-detail/:propertyId" element={<PropertyDetailPage />} />
            <Route path="/productDetail/:productId" element={<ProductDetail />} />
            <Route path="/chat/:chatId" element={<Chat />} />
            <Route path="/dashbord" element={<DashboardPage/>}/>
            <Route path="*" element={<Navigate to="/" />} />

          </Routes>
        </main>

       <Footer/>
      </div>
    </Router>
  );
}

export default App;
