// Rating.js
import React, { useEffect, useState } from 'react';
import { ref, get, set } from 'firebase/database';
import { database } from './firebase';

function Rating({ property, userId }) {
  const [userRating, setUserRating] = useState(0);
  const [userComment, setUserComment] = useState('');
  const [averageRating, setAverageRating] = useState(null);
  const [numberOfRatings, setNumberOfRatings] = useState(0);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (property) {
      const fetchRatingsAndComments = async () => {
        // Fetch ratings
        const ratingsRef = ref(database, `ratings/${property.id}`);
        const ratingsSnapshot = await get(ratingsRef);
        if (ratingsSnapshot.exists()) {
          const ratings = ratingsSnapshot.val();
          const totalRatings = Object.values(ratings);
          const sumRatings = totalRatings.reduce((acc, rating) => acc + rating, 0);
          setAverageRating((sumRatings / totalRatings.length).toFixed(1));
          setNumberOfRatings(totalRatings.length);
        }

        // Fetch comments
        const commentsRef = ref(database, `comments/${property.id}`);
        const commentsSnapshot = await get(commentsRef);
        if (commentsSnapshot.exists()) {
          setComments(Object.values(commentsSnapshot.val()));
        }
      };

      fetchRatingsAndComments();
    }
  }, [property]);

  const handleRatingChange = (event) => {
    setUserRating(parseInt(event.target.value));
  };

  const handleCommentChange = (event) => {
    setUserComment(event.target.value);
  };

  const submitRatingAndComment = async () => {
    if (!userId) {
      alert('You must be logged in to rate and comment.');
      return;
    }

    if (userRating < 1 || userRating > 5) {
      alert('Rating must be between 1 and 5.');
      return;
    }

    if (userComment.trim() === '') {
      alert('Comment cannot be empty.');
      return;
    }

    if (property) {
      // Submit rating
      const ratingRef = ref(database, `ratings/${property.id}/${userId}`);
      await set(ratingRef, userRating);

      // Submit comment
      const commentRef = ref(database, `comments/${property.id}/${userId}`);
      await set(commentRef, userComment);

      alert('Rating and comment submitted successfully!');
      setUserRating(0); // Reset rating after submission
      setUserComment(''); // Reset comment after submission

      // Fetch updated ratings and comments
      const fetchRatingsAndComments = async () => {
        const ratingsRef = ref(database, `ratings/${property.id}`);
        const ratingsSnapshot = await get(ratingsRef);
        if (ratingsSnapshot.exists()) {
          const ratings = ratingsSnapshot.val();
          const totalRatings = Object.values(ratings);
          const sumRatings = totalRatings.reduce((acc, rating) => acc + rating, 0);
          setAverageRating((sumRatings / totalRatings.length).toFixed(1));
          setNumberOfRatings(totalRatings.length);
        }

        const commentsRef = ref(database, `comments/${property.id}`);
        const commentsSnapshot = await get(commentsRef);
        if (commentsSnapshot.exists()) {
          setComments(Object.values(commentsSnapshot.val()));
        }
      };

      fetchRatingsAndComments();
    }
  };

  if (!property) return null; // Prevent rendering if property is null

  return (
    <div className="row">
      <div class="col-12">
      <h3>اعطي تقييمك</h3>
      <div className="rating">
        <label>
          <input type="radio" value="1" checked={userRating === 1} onChange={handleRatingChange} /> 1
        </label>
        <label>
          <input type="radio" value="2" checked={userRating === 2} onChange={handleRatingChange} /> 2
        </label>
        <label>
          <input type="radio" value="3" checked={userRating === 3} onChange={handleRatingChange} /> 3
        </label>
        <label>
          <input type="radio" value="4" checked={userRating === 4} onChange={handleRatingChange} /> 4
        </label>
        <label>
          <input type="radio" value="5" checked={userRating === 5} onChange={handleRatingChange} /> 5
        </label>
      </div>
      <textarea
        placeholder="Add a comment..."
        value={userComment}
        onChange={handleCommentChange}
        className="form-control mt-2"style={{ width: '100%' }}
      />
      <button className="btn btn-secondary bg-light text-dark mt-2" onClick={submitRatingAndComment}>ارسل التقييم</button>
     </div>
      {averageRating !== null && (
        <div className="average-rating mt-3 col-12">
          <h4>متوسط التقييمات: {averageRating}</h4>
          <p>عدد التقييمات: {numberOfRatings}</p>
        </div>
      )}
      <div className="comments mt-3 row">
        <h4>تعليقات المستخدمين</h4>
        {comments.length > 0 ? (
          comments.map((comment, index) => (
            <div  key={index} className="comment col-12">
              <p>{comment}</p>
            </div>
          ))
        ) : (
          <p>لا توجد تعليقات بعد.</p>
        )}
      </div>
    </div>
  );
}

export default Rating;