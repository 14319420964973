import React from 'react';

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-light text-light py-3 d-flex flex-column align-items-center">
      <p className="mb-3">© {year} Résidence Ramdane</p>
      <ul className="list-unstyled d-flex justify-content-center">
        <li className="mx-3">
          <a href="/" className="text-dark">Home</a>
        </li>
        <li className="mx-3">
          <a href="/about" className="text-dark">About Us</a>
        </li>
        <li className="mx-3">
          <a href="/contact" className="text-dark">Contact</a>
        </li>
      </ul>
    </footer>
  );
}