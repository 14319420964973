import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signInAnonymously } from 'firebase/auth';
import { ref, get, set } from "firebase/database";
import { database } from './firebase.js';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    try {
      await signInWithPopup(auth, provider);
      const user = auth.currentUser;
      if (user) {
        const usersRef = ref(database, 'users/' + user.uid);
        const snapshot = await get(usersRef);
        const userData = snapshot.val();

        const phoneNumber = user.phoneNumber;
        if (phoneNumber) {
          await set(usersRef, { ...userData, phoneNumber });
        }

        if (userData) {
          const role = userData.role;
          if (role === 'property_owner') {
            navigate('/dashbord');
          } else {
            navigate('/');
          }
        } else {
          await set(usersRef, { email: user.email, role: 'renter', phoneNumber });
          navigate('/');
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent form from submitting and refreshing the page
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      if (user) {
        const usersRef = ref(database, 'users/' + user.uid);
        const snapshot = await get(usersRef);
        const userData = snapshot.val();
        if (userData) {
          const role = userData.role;
          if (role === 'property_owner') {
            navigate('/dashbord');
          } else {
            navigate('/');
          }
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleAnonymousLogin = async () => {
    const auth = getAuth();

    try {
      await signInAnonymously(auth);
      const user = auth.currentUser;
      if (user) {
        const usersRef = ref(database, 'users/' + user.uid);
        const snapshot = await get(usersRef);
        const userData = snapshot.val();

        if (userData) {
          const role = userData.role;
          if (role === 'property_owner') {
            navigate('/dashbord');
          } else {
            navigate('/');
          }
        } else {
          await set(ref(database, 'users/' + user.uid), {email:"unknow",
            fullName:'unknow',
            phoneNumber:'unknow',
            address:"unknow",
            role: 'renter'
          });
           // Set a default role for anonymous users
          navigate('/');
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login-container border p-5">
      <div className="row">
        <div className="col-12">
          <p className='h2'><u>تسجيل الدخول</u></p>
        </div>
        <form onSubmit={handleLogin}>
          <div className="row">
            <div className="col-3">
              <label htmlFor="email">الايميل </label>
            </div>
            <div className="col-9">
              <input style={{ width: '100%' }}
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <label htmlFor="password">كلمة السر</label>
            </div>
            <div className="col-9">
              <input style={{ width: '100%' }}
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>
          <div className='col-12'>
            <button type="submit" style={{ width: '60%' }}>دخول</button>
          </div>
        </form>
        <div className='d-flex col-12 p-3'>
          <button type="button"style={{ width: '100%' }} onClick={handleGoogleLogin}>
            الدخول بحساب قوقل <i className="fab fa-google"></i>
          </button>
        </div>
        <div className='col-12' style={{ width: '100%' }}>
          <button type="button" style={{ width: '100%' } }onClick={handleAnonymousLogin}>
            الدخول كمستخدم مجهول
          </button>
        </div>
        {error && <p id="errorMessage" style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
}

export default LoginPage;