import React, { useState } from 'react';

const wilayas = [
    { value: "", label: "اختر", price: 0, deliveryTime: "", officePrice: 0 },
    { value: "1", label: "01 - Adrar / أدرار", price: 1100, deliveryTime: "3-7 ايام", officePrice: 700 },
    { value: "2", label: "02 - Chlef / الشلف", price: 600, deliveryTime: "1-2 ايام", officePrice: 350 },
    { value: "3", label: "03 - Laghouat / الأغواط", price: 950, deliveryTime: "2-4 ايام", officePrice: 550 },
    { value: "4", label: "04 - Oum El Bouaghi / أم البواقي", price: 750, deliveryTime: "2-3 ايام", officePrice: 450 },
    { value: "5", label: "05 - Batna / باتنة", price: 800, deliveryTime: "2-3 ايام", officePrice: 500 },
    { value: "6", label: "06 - Béjaïa / بجاية", price: 700, deliveryTime: "1-2 ايام", officePrice: 400 },
    { value: "7", label: "07 - Biskra / بسكرة", price: 850, deliveryTime: "2-4 ايام", officePrice: 500 },
    { value: "8", label: "08 - Béchar / بشار", price: 1100, deliveryTime: "3-7 ايام", officePrice: 700 },
    { value: "9", label: "09 - Blida / البليدة", price: 500, deliveryTime: "1-2 ايام", officePrice: 300 },
    { value: "10", label: "10 - Bouira / البويرة", price: 650, deliveryTime: "1-2 ايام", officePrice: 350 },
    { value: "11", label: "11 - Tamanrasset / تمنراست", price: 1300, deliveryTime: "4-7 ايام", officePrice: 800 },
    { value: "12", label: "12 - Tebessa / تبسة", price: 900, deliveryTime: "2-4 ايام", officePrice: 550 },
    { value: "13", label: "13 - Tlemcen / تلمسان", price: 700, deliveryTime: "2-3 ايام", officePrice: 400 },
    { value: "14", label: "14 - Tiaret / تيارت", price: 800, deliveryTime: "2-3 ايام", officePrice: 450 },
    { value: "15", label: "15 - Tizi Ouzou / تيزي وزو", price: 650, deliveryTime: "1-2 ايام", officePrice: 350 },
    { value: "16", label: "16 - Algiers / الجزائر", price: 400, deliveryTime: "1 يوم", officePrice: 250 },
    { value: "17", label: "17 - Djelfa / الجلفة", price: 900, deliveryTime: "2-4 ايام", officePrice: 500 },
    { value: "18", label: "18 - Jijel / جيجل", price: 700, deliveryTime: "1-2 ايام", officePrice: 400 },
    { value: "19", label: "19 - Sétif / سطيف", price: 750, deliveryTime: "2-3 ايام", officePrice: 450 },
    { value: "20", label: "20 - Saïda / سعيدة", price: 800, deliveryTime: "2-3 ايام", officePrice: 450 },
    { value: "21", label: "21 - Skikda / سكيكدة", price: 750, deliveryTime: "2-3 ايام", officePrice: 450 },
    { value: "22", label: "22 - Sidi Bel Abbès / سيدي بلعباس", price: 700, deliveryTime: "2-3 ايام", officePrice: 400 },
    { value: "23", label: "23 - Annaba / عنابة", price: 750, deliveryTime: "2-3 ايام", officePrice: 450 },
    { value: "24", label: "24 - Guelma / قالمة", price: 750, deliveryTime: "2-3 ايام", officePrice: 450 },
    { value: "25", label: "25 - Constantine / قسنطينة", price: 700, deliveryTime: "1-2 ايام", officePrice: 400 },
    { value: "26", label: "26 - Médéa / المدية", price: 600, deliveryTime: "1-2 ايام", officePrice: 350 },
    { value: "27", label: "27 - Mostaganem / مستغانم", price: 700, deliveryTime: "1-2 ايام", officePrice: 400 },
    { value: "28", label: "28 - M’Sila / المسيلة", price: 800, deliveryTime: "2-3 ايام", officePrice: 450 },
    { value: "29", label: "29 - Mascara / معسكر", price: 750, deliveryTime: "2-3 ايام", officePrice: 450 },
    { value: "30", label: "30 - Ouargla / ورقلة", price: 950, deliveryTime: "2-4 ايام", officePrice: 550 },
    { value: "31", label: "31 - Oran / وهران", price: 600, deliveryTime: "1-2 ايام", officePrice: 350 },
    { value: "32", label: "32 - El Bayadh / البيض", price: 900, deliveryTime: "2-4 ايام", officePrice: 500 },
    { value: "33", label: "33 - Illizi / اليزي", price: 1200, deliveryTime: "3-6 ايام", officePrice: 700 },
    { value: "34", label: "34 - Bordj Bou Arréridj / برج بوعريريج", price: 700, deliveryTime: "1-2 ايام", officePrice: 400 },
    { value: "35", label: "35 - Boumerdès / بومرداس", price: 600, deliveryTime: "1-2 ايام", officePrice: 350 },
    { value: "36", label: "36 - El Tarf / الطارف", price: 850, deliveryTime: "2-3 ايام", officePrice: 500 },
    { value: "37", label: "37 - Tindouf / تندوف", price: 1300, deliveryTime: "4-7 ايام", officePrice: 800 },
    { value: "38", label: "38 - Tissemsilt / تسمسيلت", price: 750, deliveryTime: "2-3 ايام", officePrice: 450 },
    { value: "39", label: "39 - El Oued / الوادي", price: 950, deliveryTime: "2-4 ايام", officePrice: 550 },
    { value: "40", label: "40 - Khenchela / خنشلة", price: 800, deliveryTime: "2-3 ايام", officePrice: 500 },
    { value: "41", label: "41 - Souk Ahras / سوق أهراس", price: 800, deliveryTime: "2-3 ايام", officePrice: 500 },
    { value: "42", label: "42 - Tipaza / تيبازة", price: 600, deliveryTime: "1-2 ايام", officePrice: 350 },
    { value: "43", label: "43 - Mila / ميلة", price: 750, deliveryTime: "2-3 ايام", officePrice: 450 },
    { value: "44", label: "44 - Aïn Defla / عين الدفلى", price: 650, deliveryTime: "1-2 ايام", officePrice: 350 },
    { value: "45", label: "45 - Naâma / النعامة", price: 900, deliveryTime: "2-4 ايام", officePrice: 550 },
    { value: "46", label: "46 - Aïn Témouchent / عين تموشنت", price: 700, deliveryTime: "2-3 ايام", officePrice: 400 },
    { value: "47", label: "47 - Ghardaïa / غرداية", price: 950, deliveryTime: "2-4 ايام", officePrice: 550 },
    { value: "48", label: "48 - Relizane / غليزان", price: 700, deliveryTime: "2-3 ايام", officePrice: 400 },
  ];

  function UserInfoModal({ onClose, onSave , pricepruduct }) {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [wilaya, setWilaya] = useState('');
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');
    const [price, setPrice] = useState(0);
    const [deliveryTime, setDeliveryTime] = useState('');
    const [officePrice, setOfficePrice] = useState(0);
    const [isCenterDelivery, setIsCenterDelivery] = useState(false);
  
    const handleWilayaChange = (e) => {
      const selectedWilaya = wilayas.find(w => w.value === e.target.value);
      setWilaya(e.target.value);
      setPrice(selectedWilaya.price);
      setDeliveryTime(selectedWilaya.deliveryTime);
      setOfficePrice(selectedWilaya.officePrice);
    };
  
    const handleSave = () => {
      if (!name || !phone || !city || !wilaya || !address) {
        setError('Please fill in all fields');
        return;
      }
      onSave({ name, phone, city, wilaya, address, price ,isCenterDelivery});
    };
  
    return (
      <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">ادخل معلومات</h5>
              <button type="button" className="close" onClick={onClose}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>الاسم الكامل</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>الهاتف</label>
                <input
                  type="text"
                  className="form-control"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>     
              <div className="form-group">
                <label>الولاية</label>
                <select
                  id="wilaya_id"
                  value={wilaya}
                  onChange={handleWilayaChange}
                  className="form-control"
                >
                  {wilayas.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>المدينة</label>
                <input
                  type="text"
                  className="form-control"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>عنوان التوصيل</label>
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>التوصيل إلى المركز</label>
                <input
                  type="checkbox"
                  checked={isCenterDelivery}
                  onChange={(e) => setIsCenterDelivery(e.target.checked)}
                />
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="alert alert-info">
              <strong>سعر المنتج:</strong> {pricepruduct} دج<br/>
                <strong>سعر التوصيل:</strong> {isCenterDelivery ? officePrice : price} دج<br/>
                <strong>الوقت المتوقع للتوصيل:</strong> {deliveryTime}<br/>
                <strong>التمن الكلي</strong> {Number(isCenterDelivery ? officePrice : price)+Number(pricepruduct)}<br/>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onClose}>
                غلق
              </button>
              <button type="button" className="btn btn-primary" onClick={handleSave}>
                احفظ واشتري
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default UserInfoModal;