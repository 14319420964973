import React from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import Booking from './Booking';
import Userdata from './Userdata';
function ReservationPage() {
    const { userId } = useParams();
  return (
    <div>
        <Userdata userId ={userId}/>
      
      <Booking userID ={userId}deleteo={false}/>
        
    
    </div>
  );
}

export default ReservationPage;