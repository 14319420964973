import React from 'react';
import Propertylist from './Propertylist';
import ProductList from './ProductList';


function IndexPage() {
 
  return (
    <div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <img
          style={{ width: "80%",height:300
           }}
            src="porsay.jfif"
            alt="image of the beatch porsay "
            className=" mx-auto d-block " // Use Bootstrap classes for rounded corners and padding
          />
        </div >
        </div>
        <div className='container'>
  <div className='row p-4'>
    <div className='col-sm-12 col-md-4 border'>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ height: '32px', width: '32px', fill: 'currentColor' }} aria-hidden="true" role="presentation">
        <path d="m16 .8.56.37C20.4 3.73 24.2 5 28 5h1v12.5C29 25.57 23.21 31 16 31S3 25.57 3 17.5V5h1c3.8 0 7.6-1.27 11.45-3.83L16 .8zm0 2.4-.34.2a22.58 22.58 0 0 1-10.3 3.55L5 6.97V17.5C5 24.33 9.72 28.86 15.71 29H16c6.13 0 11-4.56 11-11.5V6.97l-.35-.02a22.58 22.58 0 0 1-10.31-3.54L16 3.19zm7 7.39L24.41 12 13.5 22.91 7.59 17 9 15.59l4.5 4.5 9.5-9.5z"></path>
      </svg>
      <h2>احجز بكل طمأنينة</h2>
      <p>احجز24 ساعة على 24 ساعة</p>
    </div>
    <div className='col-sm-12 col-md-4 border'>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ height: '32px', width: '32px', fill: 'currentColor' }} viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false">
        <path d="M9.5 2a4.5 4.5 0 0 1 3.53 7.3c.6.21 1.17.54 1.66.98l.19.19L17.4 13H31v2h-2v14a2 2 0 0 1-1.85 2H5a2 2 0 0 1-2-1.85V15H1v-2h2.1a5 5 0 0 1 2.96-3.6A4.5 4.5 0 0 1 9.5 2zm7.08 13H5v14h22V15h-7.59l3.3 3.3-1.42 1.4zM9.5 4a2.5 2.5 0 0 0-1 4.8V11H8a3 3 0 0 0-2.83 2h9.41l-1.12-1.12a3 3 0 0 0-1.92-.87l-.2-.01h-.84V8.8a2.5 2.5 0 0 0-1-4.8zm15.49-3a6.96 6.96 0 0 1-1.8 4.07l-.45.46A8.97 8.97 0 0 0 20.35 11h-2a10.97 10.97 0 0 1 3.2-7.12A4.96 4.96 0 0 0 22.97 1zm2 0h2a10.96 10.96 0 0 1-3.2 7.12A4.97 4.97 0 0 0 24.38 11h-2a6.97 6.97 0 0 1 1.8-4.06l.44-.47A8.96 8.96 0 0 0 26.99 1z"></path>
      </svg>
      <h2>اختر وسائل الراحة التي تريد</h2>
      <p>استفد من وسائل الراحة التي تريدها في العقار الدي تريد تأجيره</p>
    </div>
    <div className='col-sm-12 col-md-4 border'>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ height: '32px', width: '32px', fill: 'currentColor' }} aria-hidden="true" role="presentation">
        <path d="M28 2h-6V0h-2v2h-8V0h-2v2H4a2 2 0 0 0-2 2v21a5 5 0 0 0 5 5h12.59a2.01 2.01 0 0 0 1.41-.59L29.41 21a2.01 2.01 0 0 0 .59-1.41V4a2 2 0 0 0-2-2Zm-8 25.59V23a3 3 0 0 1 3-3h4.59ZM28 10H4v2h24v6h-5a5 5 0 0 0-5 5v5H7a3 3 0 0 1-3-3V4h6v2h2V4h8v2h2V4h6Z"></path>
      </svg>
      <h2>اختر الوقت الدي يساعدك</h2>
      <p>لديك الحرية في الحجز وفق الوقت الدي يساعد خطتك في العطلة</p>
    </div>
  </div>
</div>
      
     <Propertylist deleteo={false}/>
     <ProductList deleteo={false}/>
     <h2>الاسئلة الشائعة</h2>
     <div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
        هل العقارات موجودة ومؤكدة 
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <strong>العقارات لا تنشر الى بعد التأكد منها وتحدف ادا كانت هناك شكاوى </strong>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
       هل يمكني الغاء الحجز
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <strong>يمكنك الغاء الحجز في اي وقت</strong> 
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
       هل ادفع رسوم للحجز
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <strong>الحجز مجاني وبدون اي شروط </strong> 
      </div>
    </div>
  </div>
</div>
    </div>
  );
}

export default IndexPage;