import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import { Link } from 'react-router-dom';
import { auth, database as db } from './firebase';
function Chats({userId}) {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
 // const userId = auth.currentUser ? auth.currentUser.uid : null; // Get current user ID

  useEffect(() => {
    const fetchChats = async () => {
      try {
        
        if (!userId) return;

        //const db = getDatabase();
        const chatsRef = ref(db, 'chats');
        const snapshot = await get(chatsRef);

        if (snapshot.exists()) {
          const allChats = snapshot.val();
    
          const userChats = [];

          // Iterate over each chat
          for (const chatId in allChats) {
           const chat = allChats[chatId];
          
            // Check if the current user is the owner of the chat
            if ( chat.participants["ownerId"] === userId) {
            userChats.push( { id: chatId } );
           console.log("ok")
            }
          }

          setChats(userChats);

          
        }
      } catch (error) {
        console.error('Error fetching chats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchChats();
  }, [userId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="container mt-4">
      <h2 className="mb-4 text-center">Your Chats</h2>
      {chats.length > 0  ? (
        <ul className="list-group">
          {chats.map((chat) => (
            <li key={chat.id} className="list-group-item">
              <Link to={`/chat/${chat.id}`}>مستخدم </Link>
            </li>
          ))}
        </ul>
      ) : (
        <p>No chats available.</p>
      )}
    </div>
  );
}

export default Chats;