import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, database } from './firebase';
import PropertyForm from './PropertyForm';
import Propertylist from './Propertylist';
import Booking from './Booking';
import Chats from './Chats';
import ProductForm from './ProductForm'; // Import AddProduct component
import ProductList from './ProductList';

function DashboardPage() {
  const [isPropertyFormOpen, setIsPropertyFormOpen] = useState(false);
  const [isProductFormOpen, setIsProductFormOpen] = useState(false); // State for AddProduct form
  const [userRole, setUserRole] = useState(null);
  const [properties, setProperties] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [user,setUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserRole = async () => {
      const user = auth.currentUser;

      if (user) {
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          setUserRole(userData.role);
          setUser(user);

          if (userData.role !== 'property_owner') {
            navigate('/');
          }
        } else {
          navigate('/');
        }
      } else {
        navigate('/');
      }
    };

    checkUserRole();
  }, [navigate]);

  const openPropertyForm = () => {
    setIsPropertyFormOpen(true);
  };

  const closePropertyForm = () => {
    setIsPropertyFormOpen(false);
  };

  const openProductForm = () => {
    setIsProductFormOpen(true);
  };

  const closeProductForm = () => {
    setIsProductFormOpen(false);
  };

  const addProperty = (newProperty) => {
    setProperties([...properties, newProperty]);
    setAlertMessage('Property added successfully!');
  };

  const addProduct = (newProduct) => {
    // Logic to add the new product to your state or database
    setAlertMessage('Product added successfully!');
  };

  return (
    <div>
      <div>
        <button className="btn btn-primary" onClick={openPropertyForm} data-toggle="modal" data-target="#exampleModal">
          اضافة ملكية
        </button>
        <button className="btn btn-primary" onClick={openProductForm} data-toggle="modal" data-target="#productModal">
          اضافة منتج
        </button>
      </div>
      <Chats userId={user.uid}/>
      {alertMessage && <div className="alert alert-success">{alertMessage}</div>}
      {isPropertyFormOpen && <PropertyForm onAddProperty={addProperty} closeForm={closePropertyForm} />}
      {isProductFormOpen && <ProductForm onAddProduct={addProduct} closeForm={closeProductForm} />} {/* AddProduct form */}
      <Propertylist user={user} deleteo={true} properties={properties} />
      <ProductList user={user}  deleteo={true}/>
      <div className="row"><Booking adminuser={user} deleteo={true} /></div>
    </div>
  );
}

export default DashboardPage;
