import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, database } from './firebase.js';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { ref, set } from 'firebase/database';

function RegistPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [error, setError] = useState('');

  const handleRegister = async (event) => {
    event.preventDefault();
    setError('');

    if (!email || !password || !fullName || !phoneNumber || !address) {
      setError('Please fill all the fields.');
      return;
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Save user information to the database
      await set(ref(database, 'users/' + user.uid), {email,
        fullName,
        phoneNumber,
        address,
        role: 'renter'
      });

      // Send verification email
      //await sendEmailVerification(user);

     // alert('Verification email sent. Please check your inbox.');
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row d-flex justify-content-center border">
        تسجيل
      </div>
      <form className="row" onSubmit={handleRegister}>
        <div className="col-md-6 mb-3">
          <label htmlFor="email" className="form-label">الإيميل</label>
          <input 
            type="email" 
            className="form-control" 
            id="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="ادخل الإيميل" 
            required 
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="password" className="form-label">كلمة السر</label>
          <input 
            type="password" 
            className="form-control" 
            id="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            placeholder="ادخل كلمة السر" 
            required 
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="fullName" className="form-label">الاسم الكامل</label>
          <input 
            type="text" 
            className="form-control" 
            id="fullName" 
            value={fullName} 
            onChange={(e) => setFullName(e.target.value)} 
            placeholder="ادخل الإسم الكامل" 
            required 
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="phoneNumber" className="form-label">رقم الهاتف</label>
          <input 
            type="tel" 
            className="form-control" 
            id="phoneNumber" 
            value={phoneNumber} 
            onChange={(e) => setPhoneNumber(e.target.value)} 
            placeholder="ادخل رقم الهاتف" 
            required 
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="address" className="form-label">العنوان الشخصي</label>
          <input 
            type="text" 
            className="form-control" 
            id="address" 
            value={address} 
            onChange={(e) => setAddress(e.target.value)} 
            placeholder="ادخل العنوان" 
            required 
          />
        </div>
        <div className="col-12 mb-3">
          <button type="submit" className="btn btn-primary" id="registerBtn">تسجيل</button>
        </div>
        {error && <div id="errorMessage" style={{ color: 'red' }}>{error}</div>}
      </form>
    </div>
  );
}

export default RegistPage;


