// PropertyDetailPage.js
import React, { useEffect, useState, useRef } from 'react';
import { ref, get, push, set } from 'firebase/database';
import { useParams, useNavigate } from 'react-router-dom';
import Carose from './Carose';
import { onAuthStateChanged } from 'firebase/auth';
import L from 'leaflet';
import { auth, database } from './firebase';
import Rating from './Rating';

function PropertyDetailPage() {
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const [property, setProperty] = useState(null);
  const [user, setUser] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const mapRef = useRef(null);
  const mapInstance = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(database, `properties/${propertyId}`);
      const snapshot = await get(dbRef);

      if (snapshot.exists()) {
        const propertyData = snapshot.val();
        propertyData.id = propertyId; // Add the id to the property data
        setProperty(propertyData);
        if (propertyData.location) {
          initMap(propertyData.location);
        }
      }
    };

    fetchData();
  }, [propertyId]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);
  const handleChatWithOwner = async () => {
    if (!user) {
      alert("قم بتسجيل الدخول حتى تتكلم مع صاحب الملكية ")
      navigate("/login")
      return;
    } 
    const userId = auth.currentUser.uid;
    const ownerId = property.ownerId;

    const chatsRef = ref(database, 'chats');
    const chatSnapshot = await get(chatsRef);
    let chatId;

    // Check if a chat already exists
    chatSnapshot.forEach((childSnapshot) => {
      const chatData = childSnapshot.val();
      if (
        chatData.participants[userId] &&
        chatData.participants[ownerId]
      ) {
        chatId = childSnapshot.key;
      }
    });

    if (!chatId) {
      // Create a new chat room
      const newChatRef = push(chatsRef);
      chatId = newChatRef.key;

      await set(newChatRef, {
        participants: {
          userId: userId,
          ownerId:ownerId ,
        },
        messages: {},
      });
    }

    navigate(`/chat/${chatId}`);
  };
  const initMap = (location) => {
    if (!location || !location.latitude || !location.longitude) return;

    if (!mapInstance.current) {
      const map = L.map('map').setView([location.latitude, location.longitude], 13);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);
      L.marker([location.latitude, location.longitude])
        .addTo(map)
        .bindPopup('residence ramdane')
        .openPopup();
      mapInstance.current = map;
    } else {
      mapInstance.current.setView([location.latitude, location.longitude], 13);
    }
  };

  const bookProperty = async (propertyId, userId, startDate, endDate) => {
    try {
      const bookingsRef = ref(database, 'bookings');
      const newBookingRef = push(bookingsRef);
      await set(newBookingRef, {
        propertyId,
        userId,
        startDate,
        endDate,
        etat: 'waiting',
      });
      console.log('Property booked successfully');
      return true;
    } catch (error) {
      console.error('Booking failed:', error.message);
      throw error;
    }
  };

  const handleBook = async () => {
    if (!user) {
      alert("يجب التسجيل للتكن من الحجز")
      navigate("/login")
      return;
    }

    const startDateInput = document.getElementById('startDate').value;
    const endDateInput = document.getElementById('endDate').value;

    if (!startDateInput || !endDateInput) {
      alert('من فظلك ادخل تاريخ الحجز');
      return;
    }

    const startDate = new Date(startDateInput);
    const endDate = new Date(endDateInput);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (endDate < startDate) {
      alert('End date cannot be before start date.');
      return;
    }

    if (startDate < today || endDate < today) {
      alert('Booking dates must be in the future.');
      return;
    }

    try {
      const bookingSuccess = await bookProperty(propertyId, user.uid, startDateInput, endDateInput);
      if (bookingSuccess) {
        alert('تم الحجز بنجاح يرجى الدخول الى صفحة المستخدم لرأية تأكيد الحجز');
      }
    } catch (error) {
      console.error('Booking failed:', error.message);
    }
  };

  const calculatePrice = () => {
    const startDateInput = document.getElementById('startDate').value;
    const endDateInput = document.getElementById('endDate').value;
    const startDate = new Date(startDateInput);
    const endDate = new Date(endDateInput);

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      setTotalPrice(0);
      return;
    }

    const oneDay = 24 * 60 * 60 * 1000;
    const numDays = Math.round(Math.abs((endDate - startDate) / oneDay));
    let totalPrice = 0;

    if (property) {
      const basePrice = parseFloat(property.price) || 0;
      const specialPrices = property.specialPrices || [];

      for (let i = 0; i < numDays; i++) {
        const currentDay = new Date(startDate);
        currentDay.setDate(startDate.getDate() + i);

        let pricePerDay = basePrice;

        specialPrices.forEach((specialPrice) => {
          const specialStartDate = new Date(specialPrice.startDate);
          const specialEndDate = new Date(specialPrice.endDate);
          if (currentDay >= specialStartDate && currentDay <= specialEndDate) {
            pricePerDay = parseFloat(specialPrice.price);
          }
        });

        totalPrice += pricePerDay;
      }
    }

    setTotalPrice(totalPrice);
  };

  return (
    <div className="container mt-5">
      {property ? <Carose images={property.images} /> : ""}
      <div className="mt-3">
        <div className='row'>
          <div className="col-sm-6 col-12">
        <div className="row p-3">
          <div className="col-12 text-center border">
            <h2 id="propertyTitle bg-light">{property?.title}</h2>
          </div>
        </div>
        <div className="row border p-2">
          <div className="col-12 m-1">
            <p id="propertyDescription">{property?.description}</p>
          </div>
          <button onClick={handleChatWithOwner} className="btn btn-primary bg-light text-dark">
       تكلم مع مالك العقار
      </button>
        </div>
        </div>
        <div className='col-sm-6 col-'> 
        <div className="container border  p-5">
          <div className="row">
            <div className="col-12 text-center">حدد تاريخ الحجز</div>
          </div>
          <div className="row">
            <div className="col-2">من تاريخ</div>
            <div className="col-10">
              <input type="date" className="form-control" id="startDate" placeholder="Start Date" onChange={calculatePrice} />
            </div>
          </div>
          <div className="row">
            <div className="col-2">الى تاريخ</div>
            <div className="col-10">
              <input type="date" className="form-control" id="endDate" placeholder="End Date" onChange={calculatePrice} />
            </div>
          </div>
          <div className="row">
            <div id="priceDisplay" className="col-6 text-center align-middle">السعر الكلي: dzd {totalPrice}</div>
            <div className="col-6 flex align-middle">
              <button id="bookButton" className="btn btn-primary bg-light text-dark" onClick={handleBook}>ابدأ الحجز</button>
            </div>
          </div>
        </div></div></div>
        <div className="container-fluid">
          <div className="row p-3">
            <Rating property={property} userId={user?user.uid:user} />
            <div id="map" style={{ height: 400 }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyDetailPage;

