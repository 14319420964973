import React, { useState, useEffect } from 'react';
import { ref, get, remove } from 'firebase/database';
import { database } from './firebase.js';
import ProductCard from './ProductCard';

const ProductList = ({ deleteo, user }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const productsRef = ref(database, 'products');
      const snapshot = await get(productsRef);
      const productList = [];
      snapshot.forEach(childSnapshot => {
        const productId = childSnapshot.key;
        const productData = childSnapshot.val();

        if (user) {
          productList.push({
            id: productId,
            deletable: deleteo,
            userID: user.uid,
            ...productData
          });
        } else {
          productList.push({
            id: productId,
            deletable: deleteo,
            userID: "",
            ...productData
          });
        }
      });
      
      setProducts(productList);
    };

    fetchData();
  }, [deleteo, user]);

  const handleDelete = async (id) => {
    try {
      const productRef = ref(database, `products/${id}`);
      await remove(productRef);
      setProducts(prevProducts => prevProducts.filter(product => product.id !== id));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          <h2 className="mb-4 border" style={{ textAlign: 'center' }}>
           سلع  صيفية <i className="fas fa-box-open"></i>
          </h2>
        </div>
      </div>
      {!deleteo ? (
        <div className="row">
          {products.map(product => (
            <ProductCard key={product.id} onDelete={handleDelete} product={product} />
          ))}
        </div>
      ) : (
        <div className="row">
          {products.filter(product => product.ownerId === product.userID).map(product => (
            <ProductCard key={product.id} onDelete={handleDelete} product={product} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductList;