import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import  "./PropertyCard.css";  // Assuming you'll have similar CSS for ProductCard

const ProductCard = ({ product, onDelete }) => {
  const navigate = useNavigate();

  const handleProductClick = () => {
    if (!product.deletable) {
      navigate(`/productDetail/${product.id}`);
    }
  };

  // Assuming `available` is similar to `confirmed`
  if(product.confirmed)
    {
     
    return (
      <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
        <div 
          className="card product-card h-100" 
          style={{ cursor: 'pointer' }}
        >
          <img 
            src={product.image} 
            className="card-img-top card-img-top" 
            alt={product.title} 
          />
          <div className="card-body">
            <h5 className="card-title" style={{ textAlign: 'right' }}>{product.title}</h5>
            <p className="card-text" style={{ textAlign: 'right' }}>
              <strong>Price:</strong> {product.price} DZD
            </p>
           
            {!product.deletable ? (
              <a className="btn btn-primary" onClick={handleProductClick}>View Details</a>
            ) : ""}
            {product.deletable ? (
              <a className="btn btn-danger" onClick={() => onDelete(product.id)}>Delete</a>
            ) : ""}
          </div>
        </div>
      </div>
    )}else return ""
 
};

export default ProductCard;