 // Add other services you need
 import { initializeApp } from 'firebase/app';
 import { getAuth } from 'firebase/auth';
 import { getDatabase ,ref} from 'firebase/database';
 import { getStorage } from 'firebase/storage';
 

const firebaseConfig = {
    apiKey: "AIzaSyDLiw7TcdGuPh6INU20eosKOhmwJquOwiQ",
    authDomain: "voiture-a7249.firebaseapp.com",
    databaseURL: "https://voiture-a7249.firebaseio.com",
    projectId: "voiture-a7249",
    storageBucket: "voiture-a7249.appspot.com",
    messagingSenderId: "914244708466",
    appId: "1:914244708466:web:435b9770143f286f3afaeb",
    measurementId: "G-491N5YG0EP"
  };
  

initializeApp(firebaseConfig);

// Export the Auth, Database, and Storage services for use in your React components
export const auth = getAuth();
export const database = getDatabase();
export const storage = getStorage();
