import React, { useState } from 'react';
import { storage, database, auth } from './firebase.js';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as databaseRef, push } from "firebase/database";
import imageCompression from 'browser-image-compression';

function PropertyForm({ onAddProperty, closeForm }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [images, setImages] = useState([]);
  const [commun, setCommun] = useState('maghnia');
  const [description, setDescription] = useState('');
  const [specialPrices, setSpecialPrices] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleImageChange = (e) => {
    setImages(e.target.files);
  };

  const addSpecialPrice = () => {
    setSpecialPrices([...specialPrices, { startDate: '', endDate: '', price: '' }]);
  };

  const handleSpecialPriceChange = (index, key, value) => {
    const newSpecialPrices = [...specialPrices];
    newSpecialPrices[index][key] = value;
    setSpecialPrices(newSpecialPrices);
  };

  const removeSpecialPrice = (index) => {
    const newSpecialPrices = [...specialPrices];
    newSpecialPrices.splice(index, 1);
    setSpecialPrices(newSpecialPrices);
  };

  const handleSubmit = async () => {
    if (!title || !price || !latitude || !longitude || images.length === 0) {
      setErrorMessage('Please fill out all fields and upload at least one image.');
      return;
    }

    const property = {
      title,
      price,
      location: {
        latitude,
        longitude
      },
      commun,
      description,
      ownerId: auth.currentUser.uid,
      images: []
    };

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };

      const compressImagePromises = Array.from(images).map(async (imageFile) => {
        return await imageCompression(imageFile, options);
      });

      const compressedImages = await Promise.all(compressImagePromises);

      const uploadPromises = compressedImages.map(imageFile => {
        const imageRef = storageRef(storage, 'images/' + imageFile.name);
        return uploadBytes(imageRef, imageFile).then(snapshot => getDownloadURL(snapshot.ref));
      });

      const imageUrls = await Promise.all(uploadPromises);
      property.images = imageUrls;
      property.image = imageUrls[0];
      property.specialPrices = specialPrices;

      const newPropertyRef = await push(databaseRef(database, 'properties'), property);
      onAddProperty({ id: newPropertyRef.key, ...property });
      alert("تم اضافة الملكية ");
      
    } catch (error) {
      setErrorMessage('Failed to add property. Please try again later.');
    }
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          setErrorMessage('Failed to retrieve current location.');
        }
      );
    } else {
      setErrorMessage('Geolocation is not supported by this browser.');
    }
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="modal" tabIndex="-1" role="dialog" id="exampleModal">
      <div className="modal-content">
        <div className="modal-header d-flex justify-content-end">
          <h5 className="modal-title">إضافة ملكية</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body d-flex">
          <form className="flex-grow-1">
            {currentStep === 1 && (
              <div>
                <div className="form-group row">
                  <div className="col-3">
                    <label htmlFor="property_title">عنوان للملكية:</label>
                  </div>
                  <div className="col-9">
                    <input
                      style={{ width: '80%' }}
                      id="property_title"
                      type="text"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-3">
                    <label>التمن الاساسي</label>
                  </div>
                  <div className="col-9">
                    <input
                      style={{ width: '80%' }}
                      type="number"
                      value={price}
                      onChange={e => setPrice(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <button type="button" onClick={nextStep} className="btn btn-primary">التالي</button>
              </div>
            )}
            {currentStep === 2 && (
              <div>
                <div className="row border">
                  <div className="col-12"> الموقع العالمي</div>
                  <div className="form-group col-6">
                    <label>Latitude:</label>
                    <input
                      type="text"
                      value={latitude}
                      onChange={e => setLatitude(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Longitude:</label>
                    <input
                      type="text"
                      value={longitude}
                      onChange={e => setLongitude(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <button type="button" onClick={handleGetCurrentLocation}>
                      الحصول على الموقع الحالي
                    </button>
                  </div>
                </div>
                <button type="button" onClick={prevStep} className="btn btn-secondary">رجوع</button>
                <button type="button" onClick={nextStep} className="btn btn-primary">التالي </button>
              </div>
            )}
            {currentStep === 3 && (
              <div>
                <div className="form-group">
                  <div className="d-flex align-items-end">
                    <div className="p-2">
                      <label>وصف الملكية</label>
                    </div>
                  </div>
                  <textarea
                    style={{ width: '100%' }}
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
                <div className="form-group row">
                  <div className="col-4">
                    <label>بلدية الملكية:</label>
                  </div>
                  <div className="col-8">
                    <select value={commun} onChange={e => setCommun(e.target.value)}>
                      <option value="maghnia">Maghnia</option>
                      <option value="shaybe_rasou">Shaybe Rasou</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label>صور للمكية</label>
                  <input
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                    multiple
                    required
                  />
                </div>
                <button type="button" onClick={prevStep} className="btn btn-secondary">رجوع</button>
                <button type="button" onClick={nextStep} className="btn btn-primary">التالي</button>
              </div>
            )}
            {currentStep === 4 && (
              <div>
                <h4>اسعار خاصة</h4>
                {specialPrices.map((sp, index) => (
                  <div key={index} className="special-price-entry">
                    <label>بداية السعر الخاص التاريخ</label>
                    <input
                      type="date"
                      value={sp.startDate}
                      onChange={e => handleSpecialPriceChange(index, 'startDate', e.target.value)}
                    />
                    <label>تاريخ نهاية السعر الخاص</label>
                    <input
                      type="date"
                      value={sp.endDate}
                      onChange={e => handleSpecialPriceChange(index, 'endDate', e.target.value)}
                    />
                    <label>السعر الخاص:</label>
                    <input
                      type="number"
                      value={sp.price}
                      onChange={e => handleSpecialPriceChange(index, 'price', e.target.value)}
                    />
                    <button type="button" onClick={() => removeSpecialPrice(index)}>
                      حدف
                    </button>
                  </div>
                ))}
                <button type="button" onClick={addSpecialPrice}>
                  اضافة سعر خاص
                </button>
                <div className='row'>
                  <div className='col-12'>
                <button type="button" onClick={prevStep} className="btn btn-secondary">رجوع</button>
                <button type="button" onClick={handleSubmit} className="btn btn-primary">ارسل</button>
              </div></div></div>
            )}
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default PropertyForm;

