
import React, { useEffect, useState } from 'react';
import { ref, get, remove } from 'firebase/database';
import { database } from './firebase';
import Book from './Book';

function Booking({ userID, deleteo,adminuser }) {
  const [bookings, setBookings] = useState([]);
  const [users, setUsers] = useState({});
  const [properties, setProperties] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      
      try {
        // Fetch bookings
        const bookingsRef = ref(database, 'bookings');
        const bookingsSnapshot = await get(bookingsRef);
        const bookingsData = bookingsSnapshot.exists() ? bookingsSnapshot.val() : {};

        let bookingsList = Object.keys(bookingsData).map(key => ({
          id: key,
          ...bookingsData[key]
        }));

        if (userID) {
          bookingsList = bookingsList.filter(booking => booking.userId === userID);
        }


        // Fetch users and properties concurrently
        const usersRef = ref(database, 'users');
        const propertiesRef = ref(database, 'properties');

        const [usersSnapshot, propertiesSnapshot] = await Promise.all([get(usersRef), get(propertiesRef)]);

        const usersData = usersSnapshot.exists() ? usersSnapshot.val() : {};
        const propertiesData = propertiesSnapshot.exists() ? propertiesSnapshot.val() : {};

        
        setUsers(usersData);
        setProperties(propertiesData);
        if (adminuser && properties.lenght>0) {
          
          bookingsList = bookingsList.filter(booking => properties[booking.propertyId].ownerId== adminuser.uid );
        }
        setBookings(bookingsList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userID]);

  const handleDelete = async (bookingId) => {
    try {
      const bookingRef = ref(database, `bookings/${bookingId}`);
      await remove(bookingRef);
      setBookings(prevBookings => prevBookings.filter(booking => booking.id !== bookingId));
    } catch (error) {
      console.error('Error deleting booking:', error);
    }
  };

  const handleConfirm = (bookingId, newStatus) => {
    setBookings(prevBookings =>
      prevBookings.map(booking =>
        booking.id === bookingId ? { ...booking, etat: newStatus } : booking
      )
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-5">
      <h2>الحجوزات</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="d-none d-sm-table-cell">رقم الحجز</th>
            <th>عنوان الملكية</th>
            {deleteo && <th>اسم الحاجز</th>}
            {deleteo && <th className="d-none d-sm-table-cell">رقم هاتف الحاجز</th>}
            <th className="d-none d-sm-table-cell">بداية الحجز</th>
            <th className="d-none d-sm-table-cell">نهاية الحجز</th>
            <th >حالة الحجز</th>
            {deleteo && <th className="d-none d-sm-table-cell">تأكيد الحجز</th>}
            <th className="d-none d-sm-table-cell">الحدف</th>
            
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking) => {
            const user = users[booking.userId];
            const property = properties[booking.propertyId];
            return (
              <Book
                key={booking.id}
                booking={booking}
                user={user}
                property={property}
                handleDelete={handleDelete}
                onConfirm={handleConfirm}
                deleteo={deleteo}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Booking;
